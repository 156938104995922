.social-icon {
	width: 40px;
	height: 20px;
	
	fill: #fff;
	position: relative;
    cursor: pointer;
	transition: transform 0.5s;
}

.social-icon:hover {
    transform: scale(1.5, 1.5)
}