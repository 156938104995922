*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  padding-right: 0 !important
}
.App {
  text-align: center;
}

a{
  color: white
}

@font-face {
  font-family: 'Humnst777 BlkCn BT';
  src: url('../public/fonts/tt1161m_.ttf') format('truetype');
}
