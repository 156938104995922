body {
  margin: 0;
  padding: 0;
  /* height: 100vh; <-- remove this */
  min-height: 100vh; /* add this */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
